import './donuts.css';
//import donutsSimbol from './Ativo 12.svg';
//import donutsSimbol2 from './Ativo 6.svg';
//import donutsSimbol3 from './Ativo 7.svg';
import donutsSimbol4 from './comcalda.png';

function Donuts() {
    return(
        <img 
        className='simbolo'
        src={donutsSimbol4}
        alt='simbolo da donuts'
        />
     );
};

export default Donuts